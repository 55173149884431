<template>
  <div>
     <div class="searchBox">
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="收支日期">
          <el-date-picker
            v-model="dataForm.billDate"
            type="daterange"
            align="right"
            unlink-panels range-separator="-"
            size="small"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="收支类型">
          <el-select class="item-choose"
                     v-model="dataForm.billType"
                     size="mini"
                     style="width: 100%" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="摘要">
          <el-input v-model="dataForm.summary" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="dataForm.remark" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="付款状态" prop="writeStatus">
          <el-select class="item-choose" v-model="dataForm.writeStatus" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option label="已付款" value="1"></el-option>
            <el-option label="未付款" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button v-if="newAuth('cost:finance:add')" type="primary" @click="addOrUpdateHandle('add')">新增</el-button>
          <el-button size="small" type="success" v-if="newAuth('cost:finance:export')" @click="downLoad">导出</el-button>
        </el-form-item>
        <el-form-item style="text-align: right;float: right;">
          <div class="operationList">
            <el-button size="small" type="success" v-if="newAuth('cost:receivable:update')" @click="openWriteOffPopup(1)"
                       :disabled="!selectFeeData.length">已付款核销
            </el-button>
            <el-button size="small" type="success" v-if="newAuth('cost:receivable:update')" @click="batchAudit(2)"
                       :disabled="!selectFeeData.length">反核销
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox order-cost-table">
      <el-table :data="dataList" border size="small"
                :height="this.tableHeight"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                @selection-change="selectionChangeHandle"
                v-loading="dataListLoading"
                style="width: 100%;"
                element-loading-text="正在拼命加载中。。。。">
        <el-table-column type="selection" width="35" fixed="left" align="center"></el-table-column>
        <el-table-column type="index" label="序号" width="40" align="center"></el-table-column>
        <el-table-column label="收支类型" prop="billType" width="70" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.billType === 1" size="small" type="small">收入</el-tag>
            <el-tag v-else size="danger">支出</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="收支日期" prop="billTime" width="120" align="center">
        </el-table-column>
        <el-table-column label="收支费用(元)" prop="price" width="100" align="center">
        </el-table-column>
        <el-table-column label="单据编号" prop="proofNo" width="130" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="摘要"  prop="summary" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column label="费用备注" prop="remark" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="附件" width="50" header-align="center" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, imageList)" style="color: blue;cursor:pointer" v-if="scope.row.fileNum > 0">查看</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column label="登记人" prop="createName" width="80" header-align="center" align="center">
        </el-table-column>
        <el-table-column label="登记时间" prop="createTime" width="130" header-align="center" align="center">
        </el-table-column>
        <el-table-column label="收/付款状态" prop="writeStatus"  width="60" :show-overflow-tooltip="true" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.writeStatus == 1" size="small" type="success">已付款</el-tag>
            <el-tag v-if="scope.row.writeStatus == 2" size="small" type="danger">未付款</el-tag>
            <el-tag v-if="scope.row.writeStatus == 3" size="small" type="success">已收款</el-tag>
            <el-tag v-if="scope.row.writeStatus == 4" size="small" type="danger">未收款</el-tag>
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" width="120" label="操作">
          <template slot-scope="scope">
            <el-button v-if="newAuth('cost:finance:find')" type="text" size="small"
                       @click="addOrUpdateHandle('view', scope.row.id)">详情
            </el-button>
            <el-button v-if="newAuth('cost:finance:update')" type="text" size="small"
                       @click="addOrUpdateHandle('update',  scope.row.id)">修改
            </el-button>
            <el-button v-if="newAuth('cost:finance:delete')" type="text" size="small"
                       @click="addOrUpdateHandle('delete',  scope.row.id)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <view-image ref="viewPhoto"></view-image>
  </div>
</template>

<script>
import AddOrUpdate from './detail/finance-bill-popup'
import viewImage from '@/views/commonPop/viewImage'

export default {
  data () {
    return {
      dataForm: {
        billDate: null,
        billType: null,
        summary: null,
        proofNo: null,
        remark: null,
        writeStatus: null
      },
      typeList: [
        {
          label: '收入',
          value: 1
        },
        {
          label: '支出',
          value: 2
        }
      ],
      tableHeight: '65vh',
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      imageList: [8001],
      addOrUpdateVisible: false,
      detailVisible: false,
      selectFeeData: []
    }
  },
  components: {
    AddOrUpdate,
    viewImage
  },
  created () {
    this.setDefaultDate()
    this.getDataList()
    let now = new Date()
    let start = new Date(now.getFullYear(), 0, 1)
    // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
    window.setTimeout(() => {
      this.tableHeight = '67vh'
    }, 1000)
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  mounted () {
    // let that = this
    // setTimeout(function () {
    //   that.getAllSummaries()
    // })
  },
  methods: {

    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.billDate = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100) !important'
        }
      }
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
      this.selectFeeData = val
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    // 查看照片
    viewPhoto (row, types) {
      this.$refs.viewPhoto.init(row.id, types)
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      if (todo === 'delete') {
        this.$confirm('确定进行【删除】操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl(`/financeBill/delete/${id}`),
            method: 'delete'
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '删除成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }).catch(() => {})
      } else {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(todo, id)
        })
      }
    },
    openWriteOffPopup (writeStatus) {
      this.writeOffVisible = true
      let ids = []
      this.selectFeeData.forEach(item => {
        // 已付款和申请中的不可核销
        if (item.writeStatus !== writeStatus) {
          ids.push(item.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (writeStatus === 2) {
        title = '已付款核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/financeBill/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
      this.$refs.writeOff.init(this.dataForm.billType, writeStatus, ids)
    },
    batchAudit (writeStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.writeStatus !== writeStatus) {
          ids.push(item.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (writeStatus === 2) {
        title = '反核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/financeBill/batchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            writeStatus: writeStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    downLoad () {
      let billDate = this.dataForm.billDate
      let timeParam = {}
      if (billDate && billDate.length === 2) {
        timeParam.beginDate = billDate[0]
        timeParam.endDate = billDate[1]
      }
      this.$http({
        url: this.$http.adornUrl('/financeBill/export'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'beginDate': timeParam.beginDate,
          'endDate': timeParam.endDate,
          'billType': this.dataForm.billType,
          'summary': this.dataForm.summary,
          'proofNo': this.dataForm.proofNo,
          'remark': this.dataForm.remark,
          'writeStatus': this.dataForm.writeStatus
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '财务出入账表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let timeParam = {}
      if (this.dataForm.billDate && this.dataForm.billDate.length === 2) {
        timeParam.beginDate = this.dataForm.billDate[0]
        timeParam.endDate = this.dataForm.billDate[1]
      }
      this.$http({
        url: this.$http.adornUrl('/financeBill/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'beginDate': timeParam.beginDate,
          'endDate': timeParam.endDate,
          'billType': this.dataForm.billType,
          'proofNo': this.dataForm.proofNo,
          'summary': this.dataForm.summary,
          'remark': this.dataForm.remark,
          'writeStatus': this.dataForm.writeStatus
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    }
  }
}
</script>
<style lang="less">
.order-cost-table {
  .el-table{
    overflow:visible !important;
    .el-table__fixed-right {
        background: #f5f7fa;
    }
  }
  .el-table__body-wrapper{
    z-index: 2;
    }
  .cell.el-tooltip {
    min-width: 0;
    padding: 0 !important;
  }
  .hover-row,.selected-row {
    td {
      background: rgb(250, 195, 100) !important;
    }
    .el-input__inner {
      background: rgb(250, 195, 100);
    }
  }
  .el-input__inner {
    padding: 0;
    border: 0;
    border-radius: 0;
  }
  .el-input__inner:hover {
    background: #fff
  }
}
</style>
